<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a reportes
    </v-btn>
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <div class="card-label">
            <h3>
              Generación de listado de estudiantes
              <span class="d-block text-muted pt-2 font-size-sm"
                >Generación de listado de estudiantes según el grado o
                especialidad seleccionada.</span
              >
            </h3>
            <p class="ma-0 pa-0 text-body-1">
              <strong
                >Antes de poder generar el reporte, es nesario que seleccione 2
                parametros.</strong
              >
            </p>
            <p class="ma-0 pa-0 text-body-1">
              - La lista de <strong>grupos académicos</strong> se cargará cuando
              haya elegido un grado de tercer ciclo (7°, 8° y 9°).
            </p>
            <p class="ma-0 pa-0 text-body-1">
              - La lista de
              <strong>grupos de especialidad</strong> se cargará cuando haya
              seleccionado un grado de bachillerato (1er año, 2do año y 3er
              año).
            </p>
            <p class="ma-0 pa-0 text-body-1">
              - No se puede seleccionar un grupo académico y un grupo de
              especialidad al mismo tiempo.
            </p>
          </div>
        </div>
      </div>
      <!-- end::Page header and title -->

      <div class="card-body px-1 px-sm-5">
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- begin::filters for report generation-->
              <v-card class="elevation-2 mb-5">
                <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                  Parámetros
                </p>
                <v-divider></v-divider>
                <!-- begin::Filters combos -->
                <v-container class="pa-4">
                  <v-row class="align-items-center mb-5">
                    <!-- begin::select academic level filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <!-- begin:grade combo -->
                        <v-select
                          :label="grades.isLoading ? 'Cargando...' : 'Grado'"
                          :items="grades.data"
                          :loading="grades.isLoading"
                          :disabled="grades.isLoading"
                          item-text="name"
                          item-value="id"
                          v-model="students.filters.grade_id"
                          @click="resetFilters()"
                          @change="loadTechnicalGroups(), loadAcademicGroups()"
                          outlined
                          hide-details
                        >
                          <!-- begin::selected grade item -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                          </template>
                          <!-- end::selected grade item -->
                        </v-select>
                        <!-- end:grade combo -->
                      </div>
                    </v-col>
                    <!-- end::select academic level filter-->

                    <!-- begin::select section/group filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <!-- begin:: academicGroup combo -->
                        <v-select
                          :label="
                            specialities.isLoading
                              ? 'Cargando...'
                              : 'Especialidad'
                          "
                          :items="specialities.data"
                          :loading="specialities.isLoading"
                          :disabled="
                            specialities.isLoading || !canSelectSpecialityGroups
                          "
                          item-text="grade_speciality_group_formatted"
                          item-value="id"
                          v-model="students.filters.speciality_id"
                          hide-details
                          outlined
                        >
                          <!-- begin::selected academicGroup item -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{
                                item.grade_speciality_group_formatted
                              }}</span>
                            </v-chip>
                          </template>
                          <!-- end::selected academicGroup item -->
                        </v-select>
                        <!-- end:: academicGroup combo -->
                      </div>
                    </v-col>
                    <!-- end::select section/group filter-->

                    <!-- begin::select section/group filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <!-- begin:: technicalGroup combo -->
                      <div class="d-flex align-items-center">
                        <v-select
                          :label="
                            academicGroups.isLoading ? 'Cargando...' : 'Sección'
                          "
                          :items="academicGroups.data"
                          :loading="academicGroups.isLoading"
                          :disabled="
                            academicGroups.isLoading || !canSelectAcademicGroups
                          "
                          item-text="grade_section_group_formatted"
                          item-value="id"
                          v-model="students.filters.academicGroup_id"
                          outlined
                          hide-details
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{
                                item.grade_section_group_formatted
                              }}</span>
                            </v-chip>
                          </template>
                        </v-select>
                      </div>
                      <!-- end:: technicalGroup combo -->
                    </v-col>
                    <!-- end::select section/group filter-->
                  </v-row>
                </v-container>
                <!-- end::Filters combos -->
              </v-card>
              <!-- end::filters for report generation-->

              <!-- begin::cards with generator button for each report -->
              <template>
                <v-row>
                  <!-- begin::excel with students scores col-container-->
                  <v-col cols="12" sm="8" md="8" lg="6" xl="4" class="d-flex flex-column flex-md-row">
                    <!-- begin::excel with students scores-->
                    <v-card outlined :disabled="!canGenerateReport" class="mx-2">
                      <!-- begin::card body -->
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            estudiantes
                          </div>
                          <v-list-item-title class="text-h5 mb-1">
                            Listado de estudiantes
                          </v-list-item-title>
                          <v-list-item-subtitle tag="p"
                            >Estudiantes agrupados por
                            <span class="font-weight-bold">{{
                              specialities.validGrade
                                ? "especialidad"
                                : "sección académica"
                            }}</span
                            >.</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-avatar
                          class="rounded red-pdf"
                          tile
                          icon
                          size="80"
                        >
                          <v-icon x-large color="white" class="ml-n1"
                            >mdi-file-pdf-box</v-icon
                          ></v-list-item-avatar
                        >
                      </v-list-item>
                      <!-- end::card body -->

                      <!-- begin:: card button for report generation -->
                      <v-card-actions>
                        <v-btn
                          outlined
                          rounded
                          text
                          @click="studentsList(students.filters)"
                        >
                          Generar lista
                        </v-btn>
                      </v-card-actions>
                      <!-- end:: card button for report generation -->
                    </v-card>
                    <!-- Begin:: Second excel report-->
                    <v-card outlined :disabled="!canGenerateReport" class="mx-2">
                      <!-- begin::card body -->
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            estudiantes
                          </div>
                          <v-list-item-title class="text-h5 mb-1">
                            Listado de estudiantes Excel
                          </v-list-item-title>
                          <v-list-item-subtitle tag="p"
                            >Estudiantes agrupados por
                            <span class="font-weight-bold">{{
                              specialities.validGrade
                                ? "especialidad"
                                : "sección académica"
                            }}</span
                            >.</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-avatar
                          class="rounded green darken-3"
                          tile
                          icon
                          size="80"
                        >
                          <v-icon x-large color="white" class="ml-n1"
                            >mdi-microsoft-excel</v-icon
                          ></v-list-item-avatar
                        >
                      </v-list-item>
                      <!-- end::card body -->

                      <!-- begin:: card button for report generation -->
                      <v-card-actions>
                        <v-btn
                          outlined
                          rounded
                          text
                          @click="excelStudentList(students.filters)"
                        >
                          Generar lista
                        </v-btn>
                      </v-card-actions>
                      <!-- end:: card button for report generation -->
                    </v-card>
                  </v-col>
                  <!-- end::excel with students scores col-container-->
                </v-row>
              </template>
              <!-- end::cards with generator button for each report -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import gradeRepository from "@/repositories/gradeRepository";
import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";

export default {
  name: "AcademicOrTechnicalStudentGroupList",
  title: "Listado de estudiantes | GE ITR",

  mounted() {
    this.getGrades();
  },

  data() {
    return {
      specialities: { data: [], isLoading: false, validGrade: false },
      academicGroups: { data: [], isLoading: false, validGrade: false },
      grades: { data: [], isLoading: false },
      students: { filters: {}, isLoading: false },
      encrypted: {},
    };
  },

  methods: {
    getGrades() {
      this.grades.isLoading = true;
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades.data = data;
        })
        .catch((err) => {
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.grades.isLoading = false;
        });
    },

    //Fetching academic groups depending on the id of the grade that's been selected
    loadAcademicGroups() {
      academicGroupRepository
        .getAcademicGroupsByGrade(this.students.filters.grade_id)
        .then(({ data }) => {
          this.academicGroups.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          //this.isSectionGroupLoading = false;
        });
    },

    //Fetching technical groups depending on the academicGroup id that's been selected
    loadTechnicalGroups() {
      //filtering by grade id and getting the academic level id
      let selectedGrade = this.grades.data.find(
        (grade) => grade.id === this.students.filters.grade_id
      );

      //if the selected grade doesn't contain specialities, the request wont be made
      if (selectedGrade.academic_level_id !== 2) {
        this.specialities.validGrade = false;
        this.academicGroups.validGrade = true;
        return;
      }

      //else

      //seting loading state
      this.specialities.isLoading = true;
      this.specialities.validGrade = true;
      technicalGroupRepository
        .getTechnicalGroupsByGrade(this.students.filters.grade_id)
        .then(({ data }) => {
          //fetching and saving data
          this.specialities.data = data;
        })
        .catch(() => {
          //fallback alert
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          //Stoping loading state
          this.specialities.isLoading = false;
        });
    },

    resetFilters() {
      this.students.filters = {};
      this.encrypted = {};
    },

    goBack() {
      this.$router.go(-1);
    },

    async encryptId(id) {
      try {
        const { data: encryptedId } = await ApiService.get(
          `${this.reportsApiUrl}/version/u/${id}`
        );
        return encryptedId;
      } catch (error) {
        this.fireToast({
          icon: "error",
          title: "Hubo un error al intentar generar la lista",
        });
        return;
      }
    },

    async studentsList(filters) {
      var url;

      if (this.canGenerateReport) {
        const user_id = await this.encryptId(
          this.currentUserPersonalInfo.id_user
        );

        if (!this.canSelectSpecialityGroups) {
          var gradeGroup_id = await this.encryptId(filters.academicGroup_id);

          url = await `${this.reportsApiUrl}/students/grade-section-group/${gradeGroup_id}/user/${user_id}/pdf`;
        } else if (!this.canSelectAcademicGroups) {
          var gradeGroup_id = await this.encryptId(filters.speciality_id);

          url = await `${this.reportsApiUrl}/students/grade-speciality-group/${gradeGroup_id}/user/${user_id}/pdf`;
        }

        window.open(url, "_blank");
        return;
      }
    },
    async excelStudentList(filters) {
      var url;

      if (this.canGenerateReport) {
        const user_id = await this.encryptId(
          this.currentUserPersonalInfo.id_user
        );    

        if (!this.canSelectSpecialityGroups) {
          var gradeGroup_id = await this.encryptId(filters.academicGroup_id);

          url = await `${this.reportsApiUrl}/students/grade-section-group/${gradeGroup_id}/user/${user_id}/excel`;
        } else if (!this.canSelectAcademicGroups) {
          var gradeGroup_id = await this.encryptId(filters.speciality_id);

          url = await `${this.reportsApiUrl}/students/grade-speciality-group/${gradeGroup_id}/user/${user_id}/excel`;
        }

        window.open(url, "_blank");
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    canGenerateReport() {
      const gradeSection =
        this.students.filters.grade_id &&
        this.students.filters.academicGroup_id;
      const gradeSpeciality =
        this.students.filters.grade_id && this.students.filters.speciality_id;
      return gradeSection || gradeSpeciality;
    },

    reportsApiUrl() {
      let baseURL;
      if (process.env.REPORTS_APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.REPORTS_APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },

    canSelectAcademicGroups() {
      return this.students.filters.grade_id &&
        !this.students.filters.speciality_id
        ? true
        : false;
    },

    canSelectSpecialityGroups() {
      return this.students.filters.grade_id &&
        !this.students.filters.academicGroup_id &&
        this.specialities.validGrade
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
.red-pdf {
  background-color: #d93025 !important;
}
</style>
